
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Tooltip from "primevue/tooltip";
import ProgressSpinner from "primevue/progressspinner";
import Utils from "../../utility/utils";
import PoService from "../../services/PoService";
import VendorService from "../../services/VendorService";
import MultiselectWithButton from "../UI/MultiselectWithButton.vue";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";

const poService = new PoService();
const vendorService = new VendorService(
  process.env.VUE_APP_ABSTRACTION_API as string,
);

export default defineComponent({
  components: {
    DataTable,
    Column,
    ProgressSpinner,
    Button,
    Calendar,
    MultiselectWithButton,
    InputText,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    tableName: {
      type: String,
      default: "Inventory Table",
    },
    isCustomTab: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Object,
      default: () => ({}) as any,
    },
    poNumber: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      rowsPerPage: 10,
      totalRecords: 0,
      first: 0,
      sortBy: "po_date",
      sortOrder: -1,
      rangeStart: 1,
      rangeEnd: 10,
      firstRow: 0,
      order: false,
      filters: {
        status: { value: [], matchMode: FilterMatchMode.IN },
        buyer: { value: [], matchMode: FilterMatchMode.IN },
        vendor: { value: [], matchMode: FilterMatchMode.IN },
        po_date: { value: [], matchMode: FilterMatchMode.BETWEEN },
      },
      inventory: [] as any,
      selectedStatus: ["N", "B"] as string[],
      searchedFilterVendor: [] as any,
      selectedBuyer: "",
      stati: [
        { name: "Back Order", code: "B" },
        { name: "New", code: "N" },
        { name: "Closed", code: "C" },
      ],
      selectedFilterDate: [] as any,
      selectedVendor: [] as any,
      dateStart: "",
      dateEnd: "",
      handleVendorSelectFilter: "" as any,
    };
  },
  created() {
    this.getInvetory();

    this.handleVendorSelectFilter = _debounce(async (event) => {
      const vendor = await vendorService.searchVendors(event.value);
      this.searchedFilterVendor = _uniq([
        ...vendor.vendor_items.sort((a: any, b: any) =>
          a.name.localeCompare(b.name),
        ),
        ...this.selectedVendor,
      ]);
    }, 1000);
  },
  computed: {
    ...mapGetters({
      getField: "fdict/getField",
    }),
    poStatus(): any {
      return this.getField("PO", "8").valid_value_items;
    },
  },
  watch: {
    poNumber: {
      handler() {
        this.getInvetory();
      },
    },
  },
  methods: {
    clearDateFilter() {
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate = [];
      this.filters.po_date.value = [];
      this.getInvetory();
    },
    clearVendorFilter() {
      this.selectedVendor = [];
      this.searchedFilterVendor = [];
      this.getInvetory();
    },
    clearFilterByStatus() {
      this.selectedStatus = [];
      this.getInvetory();
    },
    clearBuyerFilter() {
      this.selectedBuyer = "";
      this.getInvetory();
    },
    clearFilter() {
      this.dateStart = this.dateEnd = "";
      this.selectedFilterDate = [];
      this.filters.po_date.value = [];
      this.selectedVendor = [];
      this.searchedFilterVendor = [];
      this.selectedStatus = [];
      this.selectedBuyer = "";
      this.getInvetory();
    },
    getVendorFilter() {
      return this.selectedVendor.map((vendor: any) => {
        return vendor;
      });
    },
    formatPrice(value: number): string {
      return Utils.formatPrice(value);
    },
    setFirstRow(row: any) {
      this.firstRow = row;
    },
    async getInvetory() {
      this.isLoading = true;

      let rangeStart = this.rangeStart.toString();
      let rangeEnd = this.rangeEnd.toString();

      let sortByField = this.sortBy.replaceAll("_", ".");
      let sortOrderRecordsBy = this.sortOrder == -1 ? "DEC" : "";

      if (this.selectedFilterDate[0] != null)
        this.dateStart = this.selectedFilterDate[0];
      if (this.selectedFilterDate[1] != null)
        this.dateEnd = this.selectedFilterDate[1];

      if (this.dateStart !== "" && this.dateEnd !== "") {
        this.dateStart = this.formatDate(this.dateStart);
        this.dateEnd = this.formatDate(this.dateEnd);
      }
      await poService
        .posearch({
          sortBy: sortByField,
          sortOrder: sortOrderRecordsBy,
          rangeStart: rangeStart,
          rangeEnd: rangeEnd,
          status: this.selectedStatus,
          poDateStart: this.dateStart,
          poDateEnd: this.dateEnd,
          buyer: this.selectedBuyer,
          vendor: this.getVendorFilter(),
          selection: this.poNumber,
          correls: "vendor_name open_amount order_amount file_bin",
        })
        .then((response) => {
          this.inventory = response.po_items;
          this.totalRecords = response.total_records_found;

          this.$emit("update-purchase-orders", this.inventory);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    formatDate(date: string): string {
      return Utils.formatDate(date);
    },
    handleRowClick(event: any) {
      this.$emit("row-click", event.data);
    },
    getStatusTitle(status: string): string {
      return this.stati.find((stat: any) => stat.code === status)?.name ?? "";
    },
    onPage(event: any) {
      this.first = event.first;
      this.rowsPerPage = event.rows;
      this.rangeStart = event.first + 1;
      this.rangeEnd = event.first + event.rows;
      this.getInvetory();
    },
    sortData(event: any) {
      this.sortBy = event.sortField;
      this.sortOrder = event.sortOrder;
      this.order = this.order !== true;

      if (this.order == true) {
        this.sortOrder = 1;
      } else {
        this.sortOrder = -1;
      }
      this.rangeStart = 1;
      this.rangeEnd = 10;
      this.rowsPerPage = 10;

      this.getInvetory();
    },
    handleFilter(event: any) {
      this.getInvetory();
    },
  },
});
